import { useState, useEffect } from 'react';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../constants';

let instanceRef: ReCaptchaInstance | null = null;

export default (): (() => Promise<string>) | null => {
  const [instance, setInstance] = useState<ReCaptchaInstance | null>(
    instanceRef
  );
  useEffect(() => {
    if (!instance) {
      load(RECAPTCHA_SITE_KEY).then(instance => {
        instanceRef = instance;
        setInstance(instance);
      });
    }
  }, [instance]);

  if (instance) {
    return (actionName = 'website') => instance.execute(actionName);
  }

  return null;
};
