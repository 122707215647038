import app from 'firebase/app';
import 'firebase/auth';
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID
} from '../../src/constants';

export const anonJWT = async (): Promise<string> | undefined => {
  const authConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID
  };

  try {
    const initializedApp = app.initializeApp(authConfig, 'auth');
    const auth = initializedApp.auth();
    await auth.signInAnonymously();
    return auth.currentUser.getIdToken();
  } catch (error) {
    throw error;
  }
};
