import React from 'react';
import { graphql } from 'gatsby';
import { anonJWT } from '../../../src/lib/firebase';
import {
  GenerateLead,
  GenerateLeadErrorEvent
} from '@ph-bit/marketing.forms.generate-lead';
import { Screen } from '@ph-bit/design.ui.layouts.screen';
import { UNIFIED_API_URL } from '../../../src/constants';
import useRecaptcha from '../../../src/hooks/use-recaptcha';
import { subscribe, unsubscribe } from '@ph-bit/utils.events';

import '../global-styles/tailwind-bit.css';
import '../global-styles/screen.css';

export default function LeadPageTemplate({ data }: any) {
  const getRecaptchaToken = useRecaptcha();
  const [token, setToken] = React.useState<string>('');
  const [recaptchaToken, setRecaptchaToken] = React.useState<string>('');

  const recaptchaCallback = React.useCallback(async () => {
    if (getRecaptchaToken && recaptchaToken === '') {
      const token = await getRecaptchaToken();

      if (token && token.length) {
        setRecaptchaToken(token);
      }
    }
  }, [getRecaptchaToken, recaptchaToken]);

  React.useEffect(() => {
    recaptchaCallback();
  }, [recaptchaCallback]);

  React.useEffect(() => {
    const getToken = async () => {
      const jwt = await anonJWT();
      setToken(jwt ?? '');
    };

    getToken();

    const handler = () => {
      setRecaptchaToken('');
    };

    subscribe(GenerateLeadErrorEvent, handler);

    return () => {
      unsubscribe(GenerateLeadErrorEvent, handler);
    };
  }, []);

  const config = data.allSanityLeadForm.edges[0].node;

  return (
    <Screen
      className="pb-8 md:pb-0 z-[100] relative"
      title={
        <img
          alt={config.partnerSlug}
          src={config.logo.asset.url}
          className="max-h-8 max-w-[50%]"
        />
      }
      scrollView={{ className: '!p-8' }}
    >
      <GenerateLead
        heading={config.heading}
        subheading={config.subheading}
        userToken={token}
        apiEndpoint={`${UNIFIED_API_URL}/graphql`}
        partnerSlug={config.partnerSlug}
        recaptchaToken={recaptchaToken}
      />
    </Screen>
  );
}

export const query = graphql`
  query LeadPageTemplateQuery($id: String!) {
    allSanityLeadForm(filter: { slug: { eq: $id } }) {
      edges {
        node {
          heading
          subheading
          partnerSlug
          slug
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
